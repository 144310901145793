/*
 * @Auth: linjituan
 * @Date: 2022-03-21 19:54:50
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-21 19:55:53
 */
import request from '@/utils/request'
const baseURL = '/rc-api'

// 查询菜单列表
export function listMenu(query) {
  return request({
    url: '/internal/recyclable/permissions/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询菜单详细
export function getMenu(menuId) {
  return request({
    url: '/internal/recyclable/permissions/' + menuId,
    baseURL,
    method: 'get'
  })
}

// 查询菜单下拉树结构
export function treeselect() {
  return request({
    url: '/internal/recyclable/permissions/treeselect',
    baseURL,
    method: 'get'
  })
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(roleId) {
  return request({
    url: '/internal/recyclable/permissions/roleMenuTreeselect/' + roleId,
    baseURL,
    method: 'get'
  })
}

// 新增菜单
export function addMenu(data) {
  return request({
    url: '/internal/recyclable/permissions',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改菜单
export function updateMenu(data) {
  return request({
    url: '/internal/recyclable/permissions',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除菜单
export function delMenu(menuId) {
  return request({
    url: '/internal/recyclable/permissions/' + menuId,
    baseURL,
    method: 'delete'
  })
}
